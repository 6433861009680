<hr class=" row solid mb-0">
<!--<div class="bg-lightorange p-0" >&nbsp;</div>-->
<br>
  <div class="row">
  </div> <!--row ends-->

  <div class="row">  
    <div class="col-md-12 col-lg-3 mb-2  align-items-stretch ">  
      <!--<div class="text-blue-bold">SmartBot</div><br>-->
      <img src="./assets/img/nexsharp_logo.png" alt="nexrpa logo" width="220px"> 
      <div class="text-orange"> 
        <div>©2024 Nexsharp Technology Ltd.</div><br>
        <div >Social Media<br>
          <a href="https://www.youtube.com/" target="_blank" style="color: red;"><i class="fab  fa-youtube fa-lg"></i></a>&nbsp;
          <!--<a href="#" ><i class="fab fa-instagram fa-lg"></i></a>&nbsp;-->
          <a href="https://github.com/" target="_blank" style="color: #333;"><i class="fab fa-github fa-lg"></i></a>&nbsp;          
          <a href="https://in.linkedin.com/" target="_blank" style="color: #0e76a8;"><i class="fab fa-linkedin-in fa-lg"></i></a>&nbsp;
          <a href="https://www.facebook.com/" target="_blank" style="color: #3b5998;"><i class="fab fa-facebook fa-lg"></i></a>&nbsp;
          <a href="https://twitter.com/" target="_blank" style="color: #1DA1F2;"><i class="fab fa-twitter fa-lg"></i></a>&nbsp;
          <span><img src="./assets/img/make-in-india.png" alt="Make in India" width="120px"></span>&nbsp;
        </div>
        
        <br>
        <div>
          Subscribe
          <!--<input type="text" class="form-control" id="newsletter" placeholder="Email for Newsletter">-->
        </div>
        
        

      </div>
      
    </div>
    <div class="col-md-12 col-lg-2 mb-2 align-items-stretch ">  
      <div class="text-blue-bold">Solutions</div><br>
      <div class="text-orange"> 
        <div >Automation</div><br>
        <div >Business Process</div><br>
        <div >Technologies</div><br>
      </div> 
    </div>
    <div class="col-md-12 col-lg-2 mb-2 align-items-stretch ">  
      <div class="text-blue-bold">Products</div><br> 
      <div class="text-orange"> 
        <div >Overview</div><br>
        <div >Security</div><br>        
      </div> 
    </div>
    <div class="col-md-12 col-lg-2 mb-2 align-items-stretch ">  
      <div class="text-blue-bold">Use Case</div><br> 
      <div class="text-orange"> 
        <div >Health Care</div><br>
        <div >Invoice</div><br>        
      </div> 
    </div>

    <div class="col-md-12 col-lg-1 mb-2 align-items-stretch ">  
      <div class="text-blue-bold">Company</div><br> 
      <div class="text-orange"> 
        <div >About</div><br>
        <div >Careers</div><br>
        <div >Partners</div><br>
        <div >Blogs</div><br>
      </div> 
    </div>

    <div class="col-md-12 col-lg-2 mb-2 align-items-stretch ">  
      <div class="text-blue-bold">Documents</div><br> 
      <div class="text-orange"> 
        <div >API Details</div><br>
        <div >Downloads</div><br>        
      </div> 
    </div>
    
  </div><!--row ends-->

  <div class="row">  
    <div class="col-md-12 col-lg-7 mb-1 align-items-stretch">  
      <div class="card border-0 rounded-0 h-100 ">   
        <!--<div class="card-title mb-1 p-1 d-flex"> 
          <span class="p-2 bg-info text-white" style="float:left;font-weight:bolder;">Contact</span>          
        </div>-->
        <div class="card-body">
        
          <div class="row">
            <div class="col-md-4">
              <a class="btn-floating blue accent-1"><i class="fas fa-map-marker-alt"></i></a>
              <div>JP Nagar, Bangalore<br>India</div>             
            </div>
            <div class="col-md-4">
              <a class="btn-floating blue accent-1"><i class="fas fa-phone"></i></a>
              <div>+91 234 567 89 <br>Mon - Fri, 8:00-22:00</div>
            </div>
            <div class="col-md-4">
              <a class="btn-floating blue accent-1"><i class="fas fa-envelope"></i></a>
              <div>info@gmail.com<br>sale@gmail.com</div>              
            </div>            
          </div>
        
        </div>
      </div>
    </div> <!--col ends-->

    <div class="col-md-12 col-lg-5 mb-1 align-items-stretch">  
      <div class="card border-0 rounded-0 h-100 ">   
        <!--<div class="card-title mb-1 p-1 d-flex"> 
          <span class="p-2 bg-info text-white" style="float:left;font-weight:bolder;">Map</span>          
        </div>-->
        <div class="card-body">
          <div class="row">
          <!--<div id="map-container-google-11" class="z-depth-1-half map-container-6" style="height: 200px">
            <iframe src="https://maps.google.com/maps?q=new%20delphi&t=&z=13&ie=UTF8&iwloc=&output=embed"
              frameborder="0" style="border:0" allowfullscreen></iframe>
          </div>-->
          <div class="col-md-12 mb-2">
            <div id="map-container-google-1" class="z-depth-1-half map-container" style="height: 300px">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.6525558025432!2d77.57859231404245!3d12.865703220675961!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae6ab768a54215%3A0xe7f18505e1430870!2sJambu%20Savari%20Dinne!5e0!3m2!1sen!2sin!4v1644560091233!5m2!1sen!2sin" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
              <!--<iframe src="https://www.google.com/maps/embed/v1/place?q=Kanakpura&key=AIzaSyB_1z4LhwbT1zpfr34bnGWgwN5XKNkoNjs"></iframe>-->
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
